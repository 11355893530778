import React from 'react'
import Fade from 'react-reveal/Fade';
import './Opioid.css'
import {Helmet} from "react-helmet";

const Opioid = () => {
    return (

        <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>OPIOID ADDICTION - Joser Forever</title>
            <link rel="canonical" href="https://joserforever.biz/opioid" />
        </Helmet>

        <div className='opioid submenu'>
                <div className="opioid-content">
                    <Fade top>
                    <h1>OPIOID ADDICTION</h1>
                    </Fade>
            </div>
        </div>
        </>
    )
}

export default Opioid
