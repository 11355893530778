import React from 'react'
import Fade from 'react-reveal/Fade';
import './Cocaine.css'
import {Helmet} from "react-helmet";

const Cocaine = () => {
    return (
        <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>COCAINE ADDICTION - Joser Forever</title>
            <link rel="canonical" href="https://joserforever.biz/cocaine" />
        </Helmet>


        <div className='cocaine submenu'>

                <div className="cocaine-content">
                    <Fade top>
                    <h1>COCAINE ADDICTION</h1>
                    </Fade>
            </div>
        </div>
        </>
    )
}

export default Cocaine
