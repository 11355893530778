import React from 'react'
import Fade from 'react-reveal/Fade';
import {Helmet} from "react-helmet";

import './SA.css'

const SA = () => {
    return (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>SUBSTANCE ABUSE PROGRAMS - Joser Forever</title>
            <link rel="canonical" href="https://joserforever.biz/substance-abuse" />
        </Helmet>

        <div className='sa submenu'>

                <div className="sa-content">
                    <Fade top>
                    <h1>SUBSTANCE ABUSE PROGRAMS</h1>
                    </Fade>
            </div>
        </div>
    </>
    )
}

export default SA
