import React from 'react'
import Fade from 'react-reveal/Fade';
import './Schizoaffective.css'
import {Helmet} from "react-helmet";

const Schizoaffective = () => {
    return (
        <>   

        <Helmet>
            <meta charSet="utf-8" />
            <title>SCHIZOAFFECTIVE DISORDER - Joser Forever</title>
            <link rel="canonical" href="https://joserforever.biz/schizoaffective" />
        </Helmet>
        <div className='Schizoaffective submenu'>
                <div className="Schizoaffective-content">
                    <Fade top>
                    <h1>SCHIZOAFFECTIVE DISORDER</h1>
                    </Fade>
            </div>
        </div>
        </>
    )
}

export default Schizoaffective
