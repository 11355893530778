import React from 'react'
import Fade from 'react-reveal/Fade';
import './Meth.css'
import {Helmet} from "react-helmet";

const Meth = () => {
    return (

        <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>METH ADDICTION - Joser Forever</title>
            <link rel="canonical" href="https://joserforever.biz/meth" />
        </Helmet>

        <div className='meth submenu'>

                <div className="meth-content">
                    <Fade top>
                    <h3>- </h3>
                    <h1>METH ADDICTION</h1>
                    </Fade>
            </div>
        </div>
        </>
    )
}

export default Meth
