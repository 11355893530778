import React from 'react'
import Fade from 'react-reveal/Fade';
import './Schizophrenia.css'
import {Helmet} from "react-helmet";

const Schizophrenia = () => {
    return (
        <>   

        <Helmet>
            <meta charSet="utf-8" />
            <title>SCHIZOPHRENIA DISORDER - Joser Forever</title>
            <link rel="canonical" href="https://joserforever.biz/schizophrenia" />
        </Helmet>
        <div className='Schizophrenia submenu'>
                <div className="Schizophrenia-content">
                    <Fade top>
                    <h1>SCHIZOPHRENIA DISORDER</h1>
                    </Fade>
            </div>
        </div>
        </>
    )
}

export default Schizophrenia
