import React from 'react'
import Fade from 'react-reveal/Fade';
import './PanicDisorder.css'
import {Helmet} from "react-helmet";

const PanicDisorder = () => {
    return (
        <>   
        <Helmet>
            <meta charSet="utf-8" />
            <title>PANIC DISORDER - Joser Forever</title>
            <link rel="canonical" href="https://joserforever.biz/panic-disorder" />
        </Helmet>
        <div className='PanicDisorder submenu'>
                <div className="PanicDisorder-content">
                    <Fade top>
                    <h1>PANIC DISORDER</h1>
                    </Fade>
            </div>
        </div>
        </>
    )
}

export default PanicDisorder
